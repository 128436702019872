<template>
  <div>
    <app-datatable
      :has-add-new="false"
      show-branch-filter
      show-inventory-filter
      :headers="datatable.headers"
      :end-point="endPoint"
      :table-slots="datatable.tableSlots"
      :reset-table-data="datatable.resetTableData"
      :update-table-data="datatable.updateTableData"
      @data-loaded="handleDataLoaded()"
    >
      <template v-slot:quantity="slotProp">
        {{ $_format_number(slotProp.item.quantity) }}
      </template>

      <template v-slot:created_at="slotProp">
        <span class="d-inline-block dir-ltr">
          {{ $_format_date_time(slotProp.item.created_at) }}
        </span>
      </template>
    </app-datatable>
  </div>
</template>

<script>
export default {
  data() {
    return {
      deleteDialog: false,
      formData: {},
      editMode: false,
      datatable: {
        resetTableData: false,
        updateTableData: false,
        headers: [
          { text: this.$t('Inventory'), value: 'inventory', sortable: false },
          { text: this.$t('Name'), value: 'item.name', sortable: false },
          { text: this.$t('Code'), value: 'item.code', sortable: false },
          { text: this.$t('Quantity'), value: 'quantity', sortable: false },
          { text: this.$t('Purchase Price'), value: 'item.purchase_price', sortable: false },
          { text: this.$t('Sale Price'), value: 'item.sale_price', sortable: false },
          { text: this.$t('Created At'), value: 'created_at', sortable: true },
        ],
        tableSlots: [
          'quantity',
          'created_at',
        ]
      },
      endPoint: 'stock/'
    }
  },

  mounted() {
    this.$_section_title({ title: 'Stock', icon: 'mdil-home' })
  },

  methods: {
    handleDataLoaded() {
      this.datatable.resetTableData = false
      this.datatable.updateTableData = false
    },
  },
}
</script>

<style lang="scss" scoped>
</style>